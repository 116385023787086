import { GatsbySeo } from 'gatsby-plugin-next-seo'
import React, { useEffect, useState } from 'react'

import ArrowIcon from '../local_modules/assets/ArrowIcon'
import { ConfirmIcon } from '../local_modules/assets/ConfirmIcon'
import { HAS_WINDOW } from '../local_modules/constants'

import type { PageProps } from 'gatsby'

import '../local_modules/styles/pages/page404.scss'

function Page({ location }: PageProps) {
  const [fromUrl, setFromUrl] = useState<undefined | string>()

  useEffect(() => {
    if (!location.href || !HAS_WINDOW) {
      return
    }

    setFromUrl(
      location.href
        .replace('404?from=%252F', '')
        .replaceAll('%252F', '/')
        .replace('}', '')
    )
  }, [location.href])

  const Li = ({ text }: { text: string }) => (
    <li>
      <ConfirmIcon />
      {text}
    </li>
  )

  return (
    <div className="container-404 grid-content">
      <GatsbySeo noindex nofollow />

      <div className="back-step">
        <button onClick={() => HAS_WINDOW && window.history.back()}>
          <ArrowIcon rotate="Right" />
          <p>Voltar</p>
        </button>
      </div>

      <div className="message">
        <p>Página não encontrada</p>
        <p>Nos desculpe pelo inconveniente</p>
      </div>

      <div className="content">
        <h2>O que faço agora?</h2>

        <ul>
          <Li text="Verifique sua conexão de internet" />
          <Li text="Espere um pouco e tente novamente" />
          <Li
            text={`Confira se o endereço URL (${
              fromUrl ?? '/'
            }) foi escrito corretamente`}
          />
          <Li text="Tente atualizar a página" />
        </ul>
      </div>
    </div>
  )
}

export default Page
